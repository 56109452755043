import Heading from './Heading'
import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'

const RadioGroups = styled.div`
  display: flex;
  background: ${theme.colors.white10};
  border-radius: 12px;
  padding: 6px;
  width: fit-content;
`

const RadioGroup = styled.div`
  display: flex;

  label {
    padding: 4px 12px;
    border-radius: 8px;
    cursor: pointer;
  }

  input {
    display: none;

    &:checked {
      & + label {
        background: ${theme.colors.white};
        h5 {
          color: ${theme.colors.navy};
        }
      }
    }
  }
`

type Option = {
  text: string
  value: string
}

type Props = {
  options: Option[]
  name: string
  mode?: string
  onChange?: (value: string | number) => void
  defaultValue?: string
}

const Radio: React.FC<Props> = (props) => {
  const { options, name, onChange, defaultValue = '' } = props

  return (
    <RadioGroups>
      {options.map((_option, i) => {
        return (
          <RadioGroup key={_option.value}>
            <input
              type='radio'
              id={`${name}-${i}`}
              name={name}
              value={_option.value}
              defaultChecked={_option.value === defaultValue}
              onChange={(event) => onChange?.(event.target.value)}
            />
            <label htmlFor={`${name}-${i}`}>
              <Heading type='h5'>{_option.text}</Heading>
            </label>
          </RadioGroup>
        )
      })}
    </RadioGroups>
  )
}

export default Radio
