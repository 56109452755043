import Block from 'components/Block'
import Centraliser from 'components/Centraliser'
import { ColorScheme } from 'styles/types'
import Heading from 'components/Heading'
import Icon from 'components/Icon'
import { QuestionElementType } from 'types/strapi/components'
import React from 'react'
import Text from 'components/Text'
import styled from 'styled-components'
import theme from 'styles/theme'

type Props = {
  scheme?: ColorScheme
  title: string
  questions: QuestionElementType[]
}

export default function QuestionsBlock(props: Props) {
  const { scheme = 'white', title, questions } = props

  const [activeTab, setActiveTab] = React.useState(-1)

  return (
    <Block>
      <Centraliser>
        <Container>
          <Heading type='h2'>{title}</Heading>

          <Accordions>
            {questions.map((question, i) => {
              return (
                <Accordion
                  key={i}
                  className={`${scheme} ${i === activeTab ? 'active' : ''}`}
                  onClick={() => setActiveTab(activeTab === i ? -1 : i)}
                >
                  <Heading type='h5'>
                    {question.question}{' '}
                    <Icon scheme={scheme} icon='arrow' direction={i === activeTab ? 'down' : 'up'} />
                  </Heading>
                  <Text type='body-small' wrapper='p'>
                    {question.answer}
                  </Text>
                </Accordion>
              )
            })}
          </Accordions>
        </Container>
      </Centraliser>
    </Block>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
    margin: 0 0 48px 0;
  }

  @media ${theme.breakpoints.atleast.lg} {
    h2 {
      margin: 0 0 64px 0;
    }
  }
`

const Accordions = styled.div`
  width: 100%;
  max-width: 944px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Accordion = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  border-radius: 12px;
  background-color: ${theme.colors.paleBlue};
  transition: background-color 300ms ease;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.paleBlueHover};
  }

  & > h5 {
    margin: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    max-height: 0;
    overflow: hidden;
    width: calc(100% - 32px);
  }

  &.active {
    h5 {
      margin-bottom: 16px !important;
    }

    p {
      max-height: 100%;
    }
  }

  @media ${theme.breakpoints.atleast.lg} {
    border-top: none;
    padding: 30px 40px;
  }
`
