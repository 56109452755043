import Block from 'components/Block'
import Centraliser from 'components/Centraliser'
import Heading from 'components/Heading'
import Spacer from 'components/Spacer'
import Text from 'components/Text'
import getStrapiMedia from 'helpers/getStrapiMedia'
import { useGlobalContexts } from 'layout/GlobalContextsProvider'
import Image from 'next/image'
import styled from 'styled-components'
import theme from 'styles/theme'
import { USPBlockType } from 'types/strapi/components'

type Props = USPBlockType

export default function USPBlock(props: Props) {
  const { scheme = 'white', extraPaddingTop, extraPaddingBottom } = props

  const { usps } = useGlobalContexts()

  return (
    <Block scheme={scheme}>
      <Spacer size={{ default: extraPaddingTop ? 120 : 60, lg: extraPaddingTop ? 160 : 80 }} />

      <Centraliser>
        <Text type='body-large-pullout' align='center' color={scheme.startsWith('dark') ? 'aquamarine' : 'blue'}>
          Why Onetrace?
        </Text>

        <Spacer size={16} />

        <Heading type='h2' align='center' withMarkdown>
          Great projects start with great technology.
        </Heading>

        <Spacer size={{ default: 48, lg: 120 }} />

        <Grid>
          {usps.map((usp) => (
            <USP key={usp.id}>
              <ImageHolder>
                <Image
                  src={getStrapiMedia(
                    usp.attributes[scheme.startsWith('dark') ? 'illustration_dark' : 'illustration_light'].data,
                  )}
                  fill
                  alt=''
                />
              </ImageHolder>

              <CopyHolder>
                <Text type='body-small' wrapper='p' color='white60'>
                  <Text type='body-small-pullout' wrapper='span'>
                    {usp.attributes.heading}
                  </Text>{' '}
                  {usp.attributes.body}
                </Text>
              </CopyHolder>
            </USP>
          ))}
        </Grid>
      </Centraliser>

      <Spacer size={{ default: extraPaddingBottom ? 120 : 60, lg: extraPaddingBottom ? 160 : 80 }} />
    </Block>
  )
}

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  @media ${theme.breakpoints.atleast.md} {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }

  @media ${theme.breakpoints.atleast.md} {
    grid-template-columns: repeat(3, 1fr);
    gap: 80px;
  }
`

const USP = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-start: span 1;

  @media ${theme.breakpoints.atleast.md} {
    flex-direction: column;
  }
`

const ImageHolder = styled.div`
  position: relative;
  height: 48px !important;
  width: 48px !important;
  margin-right: 24px;

  @media ${theme.breakpoints.atleast.lg} {
    height: 64px !important;
    width: 64px !important;
    margin-right: 0px;
    margin-bottom: 24px;
  }
`

const CopyHolder = styled.div`
  flex: 1;
`
