import React, { useEffect, useState } from 'react'

import Block from 'components/Block'
import Button from 'components/Button'
import Centraliser from 'components/Centraliser'
import { ColorScheme } from 'styles/types'
import Heading from 'components/Heading'
import Icon from 'components/Icon'
import Radio from 'components/Radio'
import Select from 'components/Select'
import Spacer from 'components/Spacer'
import Text from 'components/Text'
import styled from 'styled-components'
import theme from 'styles/theme'
import { useGlobalContexts } from 'layout/GlobalContextsProvider'

type Props = {
  scheme?: ColorScheme
}

export default function PricingBlock(props: Props) {
  const { scheme = 'white' } = props

  const { setShowLeadCaptureModal } = useGlobalContexts()

  const seatRange = []
  for (let i = 5; i <= 100; i++) {
    seatRange.push({ label: `${i} seats`, value: `${i}` })
  }

  const billingCycle = [
    { text: 'Monthly', value: 'monthly' },
    { text: 'Yearly', value: 'yearly' },
  ]

  const plan = 'Onetrace Pro'
  const planPrices = React.useMemo(
    () => ({
      [plan]: 36,
    }),
    [],
  )

  const [seats, setSeats] = useState('5')
  const [billing, setBilling] = useState('monthly')

  const [gross, setGross] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [net, setNet] = useState(0)

  const formatAmount = (x: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'GBP',
    })

    return formatter.format(x)
  }

  useEffect(() => {
    const newGross = Number(seats) * planPrices[plan] * (billing === 'yearly' ? 12 : 1)
    const newDiscount = billing === 'yearly' ? newGross * 0.1 : 0
    const newNet = newGross - newDiscount
    setGross(newGross)
    setDiscount(newDiscount)
    setNet(newNet)
  }, [plan, seats, billing, planPrices])

  const features = [
    'Capture Photos',
    'Markup Drawings',
    'Record Materials',
    'Custom Forms',
    'Document Storage',
    'Share with Guests',
    'Export to PDF and Excel',
    'Approvals',
  ]

  const quoteItems = [
    { left: `${seats}x seats`, right: formatAmount(gross), highlighted: false },
    { left: 'Unlimited Projects', right: 'Included', highlighted: false },
    { left: 'Unlimited Drawings', right: 'Included', highlighted: false },
    { left: 'Unlimited Jobs', right: 'Included', highlighted: false },
  ]

  if (discount) {
    quoteItems.push({ left: '-10% discount', right: `-${formatAmount(discount)}`, highlighted: true })
  }

  return (
    <Block scheme={scheme}>
      <Centraliser>
        <Container>
          <TextIntro>
            <Heading type='h1'>Start building today</Heading>
            <Spacer size={{ default: 16, md: 24 }} />
            <Text>Scale up the number of users as your business grows.</Text>
          </TextIntro>
          <Cards>
            <LeftCard className='block-scheme-light'>
              <LeftTop>
                <Heading type='h4'>{plan}</Heading>
                <Spacer size={16} />
                <Price>
                  <Heading type='h2'>{formatAmount(planPrices[plan])}</Heading>
                  <Heading type='h4'>/month</Heading>
                </Price>
                <Heading type='h6'>Price per seat each month</Heading>
                <Spacer size={{ default: 16, md: 24 }} />
                <Button onClick={() => setShowLeadCaptureModal(true)} arrow>
                  Start building for free
                </Button>
              </LeftTop>
              <LeftBottom>
                <CheckListHeader>
                  <Heading type='h5'>Features Include:</Heading>
                </CheckListHeader>
                <CheckList>
                  {features.map((feature, i) => {
                    return (
                      <Check key={i}>
                        <Icon icon='circleCheck' scheme='light' />
                        <Text wrapper='span' type='body-small'>
                          {feature}
                        </Text>
                      </Check>
                    )
                  })}
                </CheckList>
              </LeftBottom>
            </LeftCard>
            <RightCard className='block-scheme-dark-blue'>
              <RightTop>
                <Heading type='h4'>Quick Quote</Heading>
                <Text wrapper='span' type='body-small'>
                  How many seats do you need?
                </Text>
                <Select options={seatRange} onChange={(value: string) => setSeats(value)} value={seats} mode='dark' />
              </RightTop>
              <RightMiddle>
                <Heading type='h6'>Billing cycle</Heading>
                <Radio
                  name='cycle'
                  mode='box'
                  onChange={(value) => setBilling(value as string)}
                  options={billingCycle}
                  defaultValue={billing}
                />
                <Items>
                  {quoteItems.map((item) => (
                    <Item key={item.left} className={item.highlighted ? 'orange' : ''}>
                      <Text wrapper='span' type='body-small'>
                        {item.left}
                      </Text>
                      <Text wrapper='span' type='body-small'>
                        {item.right}
                      </Text>
                    </Item>
                  ))}
                </Items>
              </RightMiddle>
              <RightBottom>
                <Total>
                  <TotalText>
                    <Heading type='h5'>Total per {billing === 'monthly' ? 'month' : 'year'}</Heading>
                    <Heading type='h5'> exc vat</Heading>
                  </TotalText>
                  <Heading type='h4'>{formatAmount(net)}</Heading>
                </Total>
                <Button arrow onClick={() => setShowLeadCaptureModal(true)}>
                  Start building for free
                </Button>
              </RightBottom>
            </RightCard>
          </Cards>
        </Container>
      </Centraliser>
    </Block>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`

const TextIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;

  a {
    width: 100% !important;
  }

  @media ${theme.breakpoints.atleast.lg} {
    flex-direction: row;
  }
`

const LeftCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  background: ${theme.colors.paleBlue};
  border-radius: 24px;

  @media ${theme.breakpoints.atleast.md} {
    padding: 48px;
  }

  @media ${theme.breakpoints.atleast.lg} {
    width: 464px;
  }
`

const LeftTop = styled.div`
  padding: 0 0 24px 0;
  margin: 0 0 24px 0;
  border-bottom: 1px solid ${theme.colors.charcoal10};

  & > h4 {
    color: ${theme.colors.blue};
  }
`
const Price = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 2px;
  margin: 0 0 10px 0;

  h2 {
    margin: 0;
    line-height: 100%;
  }
`

const LeftBottom = styled.div`
  display: flex;
  flex-direction: column;
`
const CheckListHeader = styled.div`
  margin: 0 0 16px;
  display: flex;
  gap: 4px;

  h5 {
    margin: 0;

    &:last-of-type {
      color: ${theme.colors.charcoal60};
    }
  }
`

const CheckList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 0 24px 0;

  &:last-of-type {
    margin: 0;
  }
`

const Check = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

const RightCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  background: ${theme.colors.navy};
  border-radius: 24px;

  @media ${theme.breakpoints.atleast.sm} {
    padding: 48px;
  }

  @media ${theme.breakpoints.atleast.lg} {
    width: 464px;
  }
`

const RightTop = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 24px 0;
  margin: 0 0 24px 0;
  border-bottom: 1px solid ${theme.colors.white10};

  h4 {
    margin: 0 0 16px 0;
  }

  span {
    margin: 0 0 24px 0;
  }
`

const RightMiddle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 24px 0;

  h6 {
    color: ${theme.colors.white60};
    margin: 0 0 12px 0;
  }
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0 0 0;
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    &:last-of-type {
      color: ${theme.colors.white60};
    }
  }

  &.orange {
    span {
      color: ${theme.colors.orange};
    }
  }
`

const Total = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 24px 0;
`

const TotalText = styled.div`
  display: flex;
  gap: 4px;

  h5 {
    margin: 0;
    &:last-of-type {
      color: ${theme.colors.white60};
    }
  }
`

const RightBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0 0 0;
  margin: auto 0 0 0;
  border-top: 1px solid ${theme.colors.white10};
`
